<template>
  <div>
    <div class="loader-wrapper" v-if="loader">
      <div class="image-loader"></div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Owners List'">
          <template v-slot:preview>
            <div>
              <div class="row align-items-center mb-3">
                <div class="col-sm-12 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center mb-0">
                      Show
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select>
                      entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-9">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right"
                  >
                    <label class="d-inline-flex align-items-center mb-0">
                      Search:
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                    <div class="ml-2">
                      <button
                        class="btn print excel-btn"
                        @click="excelExport()"
                      >
                        Export to Excel
                      </button>
                      <button class="btn print" @click="csvExport(csvData)">
                        Export to CSV
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End search -->
              <div class="card">
                <div class="card-body p-0">
                  <div class="row mb-md-2"></div>
                  <!-- Table -->
                  <div class="table-responsive mb-0">
                    <b-table
                      :items="owners"
                      :fields="fields"
                      responsive="true"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template v-slot:cell(password)="owners">
                        <button
                          type="button"
                          class="btn-sm btn-pending "
                          @click="setNewPassword(owners)"
                        >
                          Set Password
                        </button>
                      </template>
                      <template v-slot:cell(status)="owners">
                        <div v-if="owners.item">
                          <b-badge
                            @click="selectStatus(owners)"
                            v-if="owners.item.owner.status === 'Active'"
                            class="mr-1 badge-font badge-success cursor-pointer"
                            pill
                            variant="success"
                            >{{ owners.item.owner.status }}
                          </b-badge>
                          <b-badge
                            @click="selectStatus(owners)"
                            v-if="owners.item.owner.status === 'Suspended'"
                            class="mr-1 badge-font badge-pending cursor-pointer"
                            pill
                            variant="warning"
                            >{{ owners.item.owner.status }}
                          </b-badge>
                        </div>
                      </template>
                      <template v-slot:cell(action)="owners">
                        <div class="table-actions">
                          <a
                            href="javascript:void(0)"
                            class="reject-icon"
                            @click="deleteOwner(owners)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </a>
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
              <b-modal
                id="modal-standard-owner"
                title="Set New Password"
                title-class="font-18"
                ref="password"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk"
              >
                <form ref="form" @submit.stop.prevent="handleSubmit">
                  <b-form-group label="New Password" label-for="name-input">
                    <b-form-input
                      id="name-input"
                      type="password"
                      class="input-style"
                      v-model="new_password"
                      required
                    ></b-form-input>
                  </b-form-group>
                </form>
              </b-modal>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import XLSX from "xlsx";
import helpers from "../../helpers";
import axios from "axios";

export default {
  data() {
    return {
      loader: false,
      search: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: true,
      owners: [],
      new_password: "",
      DropDownMenu: false,
      uuid: "",
      ownerUuid: "",
      fields: [
        { key: "title", sortable: true },
        { key: "owner.name", label: "Owner", sortable: true },
        { key: "owner.mobile_number", label: "Contact", sortable: true },
        { key: "owner.email", label: "Email", sortable: true },
        { key: "password", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "action", sortable: true }
      ]
    };
  },
  computed: {
    rows() {
      return this.owners.length;
    },
    csvData() {
      return this.owners.map(item => ({
        ...item,
        owner: item.owner.name
      }));
    }
  },
  components: {
    KTCodePreview
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllOwners() {
      this.loader = true;
      axios
        .get(this.$path + "/admin/owner")
        .then(response => {
          this.owners = response.data.data;
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    deleteOwner(owners) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          this.loader = true;
          ApiService.delete(
            this.$path + "/admin/owner/" + owners.item.owner.uuid
          )
            .then(response => {
              this.loader = false;
              helpers.successMessages(this, response.data.message);
              let index = this.owners.findIndex(
                el => el.uuid === response.data.data.uuid
              );
              if (index !== -1) {
                this.owners.splice(index, 1);
              }
            })
            .catch(err => {
              this.loader = false;
              if (err.response.data.status === 400) {
                helpers.errorOther(this, err.response.data);
              }
              if (err.response.data.status === 422) {
                helpers.validationError(this, err.response.data.errors);
              }
              if (err.response.status === 404) {
                helpers.errorMessage(this, "url not found");
              }
              if (err.response.status === 500) {
                helpers.serverError(this);
              }
            });
        }
      });
    },
    resetModal() {},
    handleOk(bvModalEvt) {
      this.loader = true;
      bvModalEvt.preventDefault();
      // Trigger submit handler
      let obj = {
        password: this.new_password
      };
      ApiService.patch(
        this.$path + "/admin/owner/change/password/" + this.ownerUuid,
        obj
      )
        .then(response => {
          this.new_password = "";
          this.ownerUuid = "";
          this.loader = false;
          this.handleSubmit();
          helpers.successMessages(this, response.data.message);
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    handleSubmit() {
      this.$nextTick(() => {
        this.$bvModal.hide("modal-standard-owner");
      });
    },
    selectStatus(owners) {
      Swal.fire({
        title:
          owners.item.owner.status === "Active"
            ? "Current status: 'Active'"
            : "Current status: 'Suspended'",
        input: "select",
        inputOptions: {
          Active: "Active",
          Suspended: "Suspended"
        },
        inputPlaceholder: "Select Status",
        showCancelButton: true,
        inputValidator: value => {
          if (!value) return;
          this.loader = true;
          ApiService.patch(
            this.$path + "/admin/owner/status/" + owners.item.owner.uuid,
            { status: value }
          )
            .then(response => {
              let index = this.owners.findIndex(
                el => el.owner.uuid === response.data.data.owner.uuid
              );
              if (index !== -1) {
                this.owners[index].owner.status =
                  response.data.data.owner.status;
              }
              helpers.successMessages(this, response.data.data.message);
              this.loader = false;
            })
            .catch(err => {
              this.loader = false;
              if (err.response.data.status === 400) {
                helpers.errorOther(this, err.response.data);
              }
              if (err.response.data.status === 422) {
                helpers.validationError(this, err.response.data.errors);
              }
              if (err.response.status === 404) {
                helpers.errorMessage(this, "url not found");
              }
              if (err.response.status === 500) {
                helpers.serverError(this);
              }
            });
        }
      });
    },
    setNewPassword(owner) {
      this.ownerUuid = owner.item.owner.uuid;
      this.$refs.password.show();
    },
    clickDots(uuid) {
      this.uuid = uuid;
      if (this.DropDownMenu) {
        this.DropDownMenu = false;
      } else {
        this.DropDownMenu = true;
      }
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "Owners.csv");
      link.click();
    },
    excelExport() {
      const data = XLSX.utils.json_to_sheet(this.owners);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Owners.xlsx");
    },
    closeMenu() {
      this.DropDownMenu = false;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.closeMenu();
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.close);
    this.getAllOwners();
    this.totalRows = this.owners.length;
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  }
};
</script>
